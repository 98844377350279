import React, { useState } from 'react'
import "./password.css";

function Password({setPassword}) {

    const [input,setInput] = useState('')
    const handleSubmit = () => {
        if(input === "Gurukul@123"){
            setPassword(false)
        }
    }

  return (
    <div className='password-container' >
        <div className='password-card' >
            <h1>Password</h1>
            <input className='password-input' placeholder='Password' onChange={(e)=>setInput(e.target.value)}/>
            <button onClick={handleSubmit} >Submit</button>
        </div>
    </div>
  )
}

export default Password